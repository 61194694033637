import * as React from 'react';
import { useState } from 'react';
import { Box, Breakpoint, Button, Link, Modal, Stack } from '@mui/material';
import { BillingPlan, BREAKPOINTS } from '../../variables/constant';
import Typography from '@mui/material/Typography';
import TrustIcon from '../../components/Icons/TrustIcon';
import palette from '../../theme/palette';
import Divider from '@mui/material/Divider';
import FeatureCheckMarkIcon from '../../components/Icons/FeatureCheckMarkIcon';
import { useTheme } from '@mui/material/styles';
import { PlanInfoFragmentFragment } from '../../generated/graphql';
import PlanInfo from './PlanInfo';
import WillIcon from '../../components/Icons/WillIcon';

interface Props {
  plan: PlanInfoFragmentFragment;
  onPlanSelection: Function;
  recommendedPlan: string;
}

const PlanDetails = ({ plan, recommendedPlan, onPlanSelection }: Props) => {
  const theme = useTheme();
  const [openCompareModal, setOpenCompareModal] = useState(false);
  const handleCompareModalOpen = () => setOpenCompareModal(true);
  const handleCompareModalClose = () => setOpenCompareModal(false);

  const recommendedTag = (
    <Box
      sx={{
        display: 'flex',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        height: '1.7rem',
        position: 'absolute',
        top: 0,
        right: 10,
        justifyContent: 'center',
        alignSelf: 'end',
        backgroundColor: 'common.curiousBlue',
        width: 125,
      }}
    >
      <Box>
        <Typography
          variant="sTextInfo"
          sx={{
            height: '1.688rem',
            color: 'common.white',
            fontWeight: 400,
            fontSize: '0.875rem',
          }}
        >
          RECOMMENDED
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        [theme.breakpoints.up(BREAKPOINTS.SM as Breakpoint)]: {
          maxWidth: '28.313rem',
        },
        backgroundColor: theme.palette.common.white,
        border: plan.name === recommendedPlan ? 3 : 1,
        borderRadius: '0.25rem',
        borderColor:
          plan.name === recommendedPlan
            ? theme.palette.primary.main
            : theme.palette.common.lightGreen,
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 5,
        position: 'relative',
        [theme.breakpoints.down(BREAKPOINTS.MD as Breakpoint)]: {
          p: 3,
          maxWidth: '56rem',
          overflow: 'hidden',
          width: '100%',
        },
      }}
    >
      {plan.name === recommendedPlan ? recommendedTag : ''}
      <Stack spacing={3} alignItems={'center'}>
        <Box
          component={plan.name === BillingPlan.TrustPlan ? TrustIcon : WillIcon}
          sx={{
            width: '3.874rem',
            height: '4rem',
          }}
        />
        <Typography
          variant="mText"
          component="p"
          sx={{ color: palette.primary.main }}
        >
          {plan.name === BillingPlan.TrustPlan ? 'TRUST + WILL' : 'WILL'}
        </Typography>

        <Stack direction="row" spacing={2}>
          {/*<Typography variant="xlText">*/}
          {/*  <Typography*/}
          {/*    variant="mLabel"*/}
          {/*    sx={{*/}
          {/*      fontWeight: 700,*/}
          {/*      fontSize: '1.5rem',*/}
          {/*      verticalAlign: 'super',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    $*/}
          {/*  </Typography>*/}
          {/*  {formatAmount(plan.offer_price as string)}*/}
          {/*</Typography>*/}
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <Box
              sx={{
                width: '90.55px',
                height: '0.6px',
                border: '1px solid #000000',
                mt: 1,
              }}
            ></Box> */}
            {/* <Typography
              variant="mLabel"
              component="p"
              sx={{
                position: 'absolute',
              }}
            >
              <Typography
                variant="mLabel"
                sx={{
                  fontWeight: 700,
                  fontSize: '0.75rem',
                  verticalAlign: 'super',
                }}
              >
                $
              </Typography>
              {formatAmount(plan.original_price as string) + '+'}
            </Typography> */}
          </Box>
        </Stack>
        <Typography
          variant="smText"
          sx={{
            height: '6rem',
          }}
        >
          {plan.description}
        </Typography>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ backgroundColor: theme.palette.common.lightGreen }}
        />
      </Stack>
      <Stack
        direction="column"
        alignSelf="flex-start"
        spacing={2}
        sx={{
          mt: 3,
          mb: 4.5,
          height: '16rem',
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            height: '19rem',
          },
        }}
      >
        <Typography variant="sTextInfo">FEATURES</Typography>
        {plan.additional_info?.map((feature, index) => {
          return (
            <Box display="flex" key={index}>
              <Box component={FeatureCheckMarkIcon} sx={{ mr: 1 }} />
              <Box>
                <Typography variant="mTextInfo">{feature}</Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
      <Button
        onClick={() => onPlanSelection(plan.id)}
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          height: 50,
        }}
      >
        Continue
      </Button>
      <Typography
        variant="sTextInfo"
        color={theme.palette.common.richBlack}
        sx={{
          mt: 2,
          textAlign: 'center',
        }}
      >
        Pay Only When You Finish
      </Typography>
      <Box display="flex" justifyContent="center">
        <Link
          onClick={handleCompareModalOpen}
          variant="mLink"
          color={theme.palette.primary.main}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            mt: 4.5,
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              mt: 5,
            },
          }}
        >
          View More Details
        </Link>
      </Box>
      <Modal
        open={openCompareModal}
        onClose={handleCompareModalClose}
        sx={{
          overflow: 'scroll',
        }}
      >
        <PlanInfo
          plan={plan}
          handleCompareModalClose={handleCompareModalClose}
        />
      </Modal>
    </Box>
  );
};

export default React.memo(PlanDetails);
